import { Resource } from '@shared/enums';

export const appMenuConfig = [
  {
    title: '',
    items: [
      {
        id: 'myfpar',
        label: 'MyFPAR',
        icon: 'dashboard',
        route: '/myfpar',
        resourceAccess: [Resource.ViewMyFparPage],
      },
      {
        id: 'profile-cover-sheet',
        label: 'Profile Cover Sheet',
        icon: 'feed',
        route: '/profile-cover-sheet',
        resourceAccess: [Resource.ViewGrantPortal],
      },
      {
        id: 'grant-portal',
        label: 'Data Portal',
        icon: 'fact_check',
        route: '/grant/portal',
        resourceAccess: [Resource.ViewGrantPortal],
      },
      {
        id: 'review-portal',
        label: 'Data Portal',
        icon: 'fact_check',
        route: '/review/portal',
        resourceAccess: [Resource.ViewReviewPortal],
      },
      {
        id: 'my-grants',
        label: 'My Grants',
        icon: 'bookmark_added',
        route: '/my-grants',
        resourceAccess: [Resource.AccessMyGrants],
      },
      {
        id: 'clinic-locator',
        label: 'Clinic Locator',
        icon: 'medication',
        route: '/clinic-locator',
      },
      {
        id: 'dashboard',
        label: 'Dashboard',
        icon: 'assessment',
        route: '/dashboard',
        resourceAccess: [Resource.AccessReportingDashboard],
      },
      {
        id: 'table-archive',
        label: 'Table Archive',
        icon: 'table_chart',
        route: '/table-archive',
        resourceAccess: [Resource.AccessTableArchive],
      },
      {
        id: 'users',
        label: 'Users',
        icon: 'people_alt',
        route: '/users',
        resourceAccess: [Resource.ListUsers],
      },
      {
        id: 'help',
        label: 'Help',
        icon: 'help',
        route: '/help',
      },
    ],
  },
];
